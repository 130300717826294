<template>

    <div id="home" class="wealthlending">

      <header style="background-color:#ffffff;">
          <!-- add 'sticky' to the class='header' to add the 'sticky' styles -->
          <div class="header-white">
              <div class="header__logo">
                  <a href="/">
                      <!-- eslint-disable-next-line vue/no-parsing-error -->
                      <svg width="184px" height="37px" viewBox="0 0 184 37" version="1.1" xmlns="https://www.w3.org/2000/svg" xmlns:xlink="https://www.w3.org/1999/xlink">
                          <!-- Generator: Sketch 43 (38999) - http://www.bohemiancoding.com/sketch -->
                          <desc>Created with Sketch.</desc>
                          <defs></defs>
                          <g id="PageLogo" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                              <g id="BRLogo" fill-rule="nonzero" fill="#00578E">
                                  <g id="GroupLogo1" transform="translate(46.000000, 7.000000)">
                                      <path
                                          d="M6.5,21.8 L0.1,21.8 L0.1,2.3 L6.6,2.3 C10.2,2.3 13.3,3.5 13.3,7.2 C13.3,9.7 12,11.1 9.8,11.7 L9.8,11.8 C12.1,12.2 14.1,13.7 14.1,16.5 C14.2,20.4 11,21.8 6.5,21.8 M6.2,5.4 L3.8,5.4 L3.8,10.3 L6.1,10.3 C8.3,10.3 9.3,9.5 9.3,7.8 C9.3,6.2 8.2,5.4 6.2,5.4 M6.1,13.3 L3.7,13.3 L3.7,18.7 L5.9,18.7 C8.3,18.7 9.5,18 9.5,16.1 C9.6,14.2 8.5,13.3 6.1,13.3"
                                          id="ShapeLogo1"></path>
                                      <path
                                          d="M24.8,11 C24.6,10.9 24.1,10.7 23.3,10.7 C21.6,10.7 20.5,12.2 20.2,12.9 L20.2,21.9 L16.3,21.9 L16.3,7.5 L20.2,7.5 L20.2,9.4 C20.8,8.3 22,7.1 23.8,7.1 C24.9,7.1 25.4,7.3 25.7,7.5 L24.8,11 Z"
                                          id="ShapeLogo2"></path>
                                      <path
                                          d="M32.7,22.2 C27.7,22.2 25.5,18.4 25.5,14.8 C25.5,11.1 27.6,7.3 32.6,7.3 C37.6,7.3 39.8,11 39.8,14.7 C39.9,18.2 37.7,22.2 32.7,22.2 M32.6,10.1 C30.4,10.1 29.7,12 29.7,14.6 C29.7,17.2 30.6,19.2 32.8,19.2 C35,19.2 35.8,17.4 35.8,14.7 C35.8,12 34.8,10.1 32.6,10.1"
                                          id="ShapeLogo3"></path>
                                      <path
                                          d="M49.5,21.8 C49.4,21.4 49.3,20.7 49.3,20.3 C48.6,21.1 47.3,22.2 45.3,22.2 C42.9,22.2 41,20.7 41,18.2 C41,14.5 44.8,13.1 48.6,13.1 L49.1,13.1 L49.1,12.3 C49.1,11.1 48.6,10.3 46.8,10.3 C44.9,10.3 43.7,11.3 43.2,11.6 L41.5,9.2 C42.3,8.4 44.2,7.2 47.3,7.2 C51.2,7.2 53,8.7 53,12.5 L53,17.6 C53,19.5 53.2,20.9 53.4,21.8 L49.5,21.8 L49.5,21.8 Z M48.7,15.5 L48.2,15.5 C45.9,15.5 44.3,16.2 44.3,17.9 C44.3,19 45.1,19.4 46,19.4 C47.4,19.4 48.2,18.6 48.7,18.1 L48.7,15.5 Z"
                                          id="ShapeLogo4"></path>
                                      <path
                                          d="M61.3,7.2 C63,7.2 64.1,7.8 64.6,8.2 L64.6,0.8 L68.5,0.8 L68.5,17.7 C68.5,19.6 68.7,21 68.9,21.8 L65,21.8 C64.9,21.4 64.7,20.7 64.7,20.2 C63.8,21.2 62.6,22.1 60.7,22.1 C57.1,22.1 54.8,19.4 54.8,14.6 C55,10.1 57.7,7.2 61.3,7.2 M62,19 C63.4,19 64.4,18 64.7,17.6 L64.7,11.2 C64.2,10.8 63.5,10.3 62.2,10.3 C60.2,10.3 59.1,12 59.1,14.8 C59.1,17.4 60,19 62,19"
                                          id="ShapeLogo5"></path>
                                      <path
                                          d="M80,11 C79.8,10.9 79.3,10.7 78.5,10.7 C76.8,10.7 75.7,12.2 75.4,12.9 L75.4,21.9 L71.5,21.9 L71.5,7.5 L75.4,7.5 L75.4,9.4 C76,8.3 77.2,7.1 79,7.1 C80.1,7.1 80.6,7.3 80.9,7.5 L80,11 Z"
                                          id="ShapeLogo6"></path>
                                      <path
                                          d="M84.4,5.7 C83.1,5.7 82.2,4.7 82.2,3.5 C82.2,2.4 83.1,1.4 84.4,1.4 C85.7,1.4 86.6,2.4 86.6,3.5 C86.6,4.7 85.7,5.7 84.4,5.7"
                                          id="ShapeLogo7"></path>
                                      <rect id="RectanglepathLogo" x="82.4" y="7.5" width="3.9" height="14.3">
                                      </rect>
                                      <path
                                          d="M94.9,7.2 C96.6,7.2 97.7,7.8 98.2,8.2 L98.2,0.8 L102.1,0.8 L102.1,17.7 C102.1,19.6 102.3,21 102.5,21.8 L98.6,21.8 C98.5,21.4 98.3,20.7 98.3,20.2 C97.4,21.2 96.2,22.1 94.3,22.1 C90.7,22.1 88.4,19.4 88.4,14.6 C88.5,10.1 91.3,7.2 94.9,7.2 M95.6,19 C97,19 98,18 98.3,17.6 L98.3,11.2 C97.8,10.8 97.1,10.3 95.8,10.3 C93.8,10.3 92.7,12 92.7,14.8 C92.7,17.4 93.6,19 95.6,19"
                                          id="ShapeLogo8"></path>
                                      <path
                                          d="M118.2,7.2 L118.2,10.1 L116.3,10.1 C116.7,10.8 116.8,11.6 116.8,12.4 C116.8,15.5 114.6,17.6 110.7,17.6 C110.1,17.6 109.5,17.5 109,17.5 C108.8,17.7 108.5,17.9 108.5,18.3 C108.5,19.1 109.3,19.2 111.4,19.3 C114.4,19.4 118.1,19.5 118.1,23.7 C118.1,26.7 115,28.9 110.3,28.9 C106.4,28.9 104,27.7 104,25.1 C104,23.5 105,22.4 106.4,21.6 C105.6,21.1 105.1,20.5 105.1,19.4 C105.1,18.1 105.9,17.2 106.7,16.7 C105.1,15.8 104.2,14.3 104.2,12.4 C104.2,9.3 106.6,7.2 110.5,7.2 L118.2,7.2 M107.3,24.3 C107.3,25.4 108.1,26.2 110.1,26.2 C112.5,26.2 113.8,25.3 113.8,24.1 C113.8,22.6 112.5,22.6 110.4,22.5 C109.7,22.4 109.1,22.4 108.5,22.3 C107.7,22.8 107.3,23.4 107.3,24.3 M113.1,12.4 C113.1,10.7 112.1,9.7 110.5,9.7 C108.9,9.7 107.9,10.7 107.9,12.4 C107.9,14 108.9,15.1 110.5,15.1 C112.1,15 113.1,14 113.1,12.4"
                                          id="ShapeLogo9"></path>
                                      <path
                                          d="M126.7,19.3 C128.6,19.3 129.7,18.6 130.4,18.3 L131.7,20.8 C130.8,21.3 129.3,22.2 126.4,22.2 C121.6,22.2 118.9,19.2 118.9,14.6 C118.9,10.1 122,7.2 126,7.2 C130.5,7.2 132.6,10.3 132.1,15.6 L123,15.6 C123.2,17.8 124.3,19.3 126.7,19.3 L126.7,19.3 Z M128.4,12.9 C128.4,11.3 127.7,10 125.9,10 C124.3,10 123.3,11.1 123.1,12.9 L128.4,12.9 Z"
                                          id="ShapeLogo10"></path>
                                      <path
                                          d="M135.4,11.2 C134.3,11.2 133.4,10.3 133.4,9.2 C133.4,8.1 134.3,7.2 135.4,7.2 C136.5,7.2 137.4,8.1 137.4,9.2 C137.4,10.3 136.5,11.2 135.4,11.2 L135.4,11.2 Z M135.4,7.5 C134.4,7.5 133.7,8.2 133.7,9.2 C133.7,10.2 134.4,10.9 135.4,10.9 C136.4,10.9 137.1,10.2 137.1,9.2 C137.1,8.2 136.4,7.5 135.4,7.5 L135.4,7.5 Z M136,10.4 L135.5,9.6 C135.4,9.4 135.3,9.4 135.1,9.4 L135.1,10.5 L134.7,10.5 L134.7,8 L135.5,8 C136,8 136.3,8.3 136.3,8.7 C136.3,9.1 136.1,9.3 135.7,9.3 C135.8,9.4 135.9,9.5 136,9.6 L136.5,10.4 L136,10.4 L136,10.4 Z M135.4,8.3 L135.1,8.3 L135.1,9 L135.3,9 C135.6,9 135.8,9 135.8,8.6 C135.8,8.5 135.7,8.3 135.4,8.3 L135.4,8.3 Z"
                                          id="ShapeLogo11"></path>
                                  </g>
                                  <g id="GroupLogo2">
                                      <polygon id="ShapeLogo12" points="18.4 19.4 18.5 19.4 18.5 19.4"></polygon>
                                      <path
                                          d="M33.7,0.1 L33.7,0.1 L0.8,0 L0.8,0.1 L0.8,37 L33.7,37 L33.7,0.1 L33.7,0.1 Z M26.5,25.3 L26.5,26.9 C22.9,26.9 20.6,25.8 19.2,24.3 C19.4,25 19.7,25.6 20,26.3 C22.5,31.1 26.8,33.6 33,33.7 L33,35.7 C24.2,35.6 20,31 18.1,27.1 C17.7,26.3 17.3,25.4 17.1,24.6 C14.2,31.8 6.2,31.9 6.1,31.9 L6.1,29.7 C6.5,29.7 15.9,29.4 15.9,18.6 L18.3,18.6 C18.3,7.8 27.7,7.7 28.1,7.7 L28.1,5.5 C28,5.5 20,5.6 17.1,12.8 C16.8,12 16.5,11.1 16.1,10.3 C14.2,6.4 10,1.8 1.2,1.7 L1.2,0.7 L33,0.7 L33,18.7 L18.5,18.7 C18.4,18.8 18.6,25.3 26.5,25.3 Z M1.3,3.6 C7.6,3.7 11.9,6.2 14.3,11 C14.7,11.7 14.9,12.4 15.1,13 C13.7,11.6 11.4,10.4 7.8,10.4 L7.8,12 C15.8,12 15.9,18.2 15.9,18.5 L1.3,18.5 L1.3,3.6 Z"
                                          id="ShapeLogo13"></path>
                                  </g>
                              </g>
                          </g>
                      </svg>
                  </a>
              </div>
          </div>

      </header>

      <!--Wealth Lending Network Code-->

      <section class="hero">
        
          <div class="outer-wrapper">
              <div class="inner-wrapper">
                  <div class="hero__content" style="margin-bottom:0; opacity: 1; transform: matrix(1, 0, 0, 1, 0, 0);">
                      <div class="hero__content-wrapper">
                          <h1 class="hero__title">Thank you.</h1>
                          <h3 class="hero__title">{{$route.params.text}}</h3>
                            <a href="/" class="form__field__input form__field__input--button cta">Go back</a>
                      </div>
                  </div>
              </div>
          </div>
      </section>

      <!--End Wealth Lending Network Code-->
      <footer>
          <div class="footer">
              <div class="footer__main">
                  <div class="outer-wrapper">
                      <div class="inner-wrapper">
                          <div class="logo">
                              <img src="../assets/images/BR_logo_white_footer.png"
                                  title="Broadridge Logo" alt="Broadridge Logo">
                          </div>
                          <div class="copy">Broadridge, a global Fintech leader with over $4.5 billion in revenues, helps clients get ahead of today's challenges to capitalize on what's next with communications, technology, data and intelligence solutions that help transform their businesses.</div>
                          <div class="logo">
                              <img src="../assets/images/RfN_logo_footer.png"
                                  title="Ready For Next image" alt="Ready For Next image">
                          </div>                        
                      </div>
                  </div>
              </div>
              <div class="footer__bottom-nav">
                  <div class="outer-wrapper">
                      <div class="inner-wrapper">
                          <div class="footer__copyright"><p>&copy; 2021 Broadridge Financial Solutions, Inc.&nbsp;<br class="show-small"> All Rights
                              Reserved. </p>
                              <div class="footer__copyright__links">
                                  <a href="https://www.broadridge.com/legal/privacy-statement-english" target="_self">Privacy Statement</a>
                                  <a href="https://www.broadridge.com/legal/terms-of-use" target="_self">Terms of Use &amp; Linking Policy</a>
                                  <a href="https://www.broadridge.com/legal/privacy-statement-english#cookies" target="_self">Cookie Policy</a>
                                  <a href="https://www.broadridge.com/legal/legal-statements" target="_self">Legal Statements</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </footer>

    </div>

</template>

<style lang="scss">
  @import url('../assets/css/main.css');
  @import url('../assets/css/cp_style.css');
  @import url('../assets/css/wealthlendingnetwork.css');
</style>

<script>

export default {
  name: 'Home',
  data() {
    return {
      isLoading: false,
      formSubmission: {},
      formErrors: []
    };
  },
  computed: {
    apiUrl()
    {
      return process.env.VUE_APP_API_ENDPOINT;
    }
  },
  methods: {
    submitForm() {

      // Set the Is Loading flag.
      this.isLoading = true;

      // Build the API endpoint URL.
      let endpoint = `${this.apiUrl}submit-request`;

      this.$http.post(endpoint, this.formSubmission)
        .then((res) => {

          // All good. Redirect to Confirmation.
          localStorage.setItem('submissionResp', JSON.stringify(res.data));
          this.$router.push('confirmation');

        })
        .catch(res => {

          this.formErrors = res.data;

        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
}
</script>
